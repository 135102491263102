import * as React from "react";
import { useRef, useEffect } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import "../styles/styles.scss";

// markup
const IndexPage = () => {
    gsap.registerPlugin(ScrollTrigger);

    const homeSection = useRef(null);
    const aboutSection = useRef(null);
    const locationSection = useRef(null);
    const mentionsSection = useRef(null);

    const scrollDown = (ref) => {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: 'smooth',
        });
    };

    useEffect(() => {
        gsap.utils.toArray('.rule--animated').forEach(elem => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: elem,
                    // scrub: true,
                    toggleActions: 'play none none reverse',
                    start: "50% center",
                    end: "100% center",
                    // markers: true
                },
            });

            tl.to(elem.getElementsByClassName('ruleImage'),{keyframes: {
                "0%":   {bottom: '30%', rotateZ:0, opacity: 0},
                "50%":  {opacity: 1},
                "100%": {bottom: '66%', rotateZ:10},
            }});
        });

        ScrollTrigger.create({
            trigger: '.main-body',
            toggleClass: {targets: ".menu", className: "menu-dark"},
            start: "top top",
            end: "bottom top",
            // markers: true
        });
    }, []);

    return (
        <main className="websiteWrapper">
            <link rel="stylesheet" href="https://use.typekit.net/bnz4uek.css" />
            <div className="menu">
                <button className="menuItem" onClick={() => scrollDown(homeSection)}>Acasa?</button>
                <button className="menuItem" onClick={() => scrollDown(aboutSection)}>Despre</button>
                <button className="menuItem" onClick={() => scrollDown(locationSection)}>Locatie</button>
                <button className="menuItem" onClick={() => scrollDown(mentionsSection)}>Mentiuni</button>
            </div>
            <section className="header" ref={homeSection}>
                <div className="container">
                    <div className="headerContent">
                        <div className="hc">
                            <div>
                                <h5 className="sans-serif uppercase light">Vidrasan</h5>
                                <h1 className="serif uppercase">Carmen &amp; Dan</h1>
                            </div>
                            <div>
                                <h5 className="sans-serif uppercase light">data</h5>
                                <h1 className="serif uppercase">29.10.2022</h1>
                            </div>
                            <div class="spaced-top">
                                <h5 className="sans-serif uppercase light">ora</h5>
                                <h1 className="serif uppercase">15:00</h1>
                                <p className="small sans-serif uppercase light">La restaurant</p>
                            </div>
                        </div>
                        <a href="https://forms.gle/JhekHcm9qUsBLWHu7"  target="_blank" rel="noreferrer noopener" className="button button--large" >Partikip</a>
                    </div>
                    <StaticImage
                        className="headerBackground"
                        src="../images/headerImage.jpg"
                        layout="fullWidth"
                        placeholder="blurred"
                    />
                </div>
            </section>
            <div className="main-body">
                <section className="section" ref={aboutSection}>
                    <div className="container">
                        <div className="sectionTitle">
                            <h5 className="sans-serif uppercase light text--grey">Nunta</h5>
                            <h1 className="serif uppercase">In sfarsit</h1>
                        </div>
                        <h6 className="main_point">
                            Asemeni albumului lui Dobrescu, treaba asta s-a lasat
                            asteptata, dar ar fi cazul sa o facem.
                        </h6>
                        <p className="paragraph">
                            Dupa 10 ani de la petrecerea la care ne-am intalnit si
                            momentul in care m-a cerut Carmen, dupa 9 ani de cand
                            ne-am mutat impreuna, dupa 5 ani de logodna, am decis sa
                            scapam pentru totdeauna de intrebarea: “cand se-ntampla
                            nunta?”. Pe 29 Octombrie 2022. Ne vedem.
                        </p>
                    </div>
                </section>
                <section className="section section--centered" ref={locationSection}>
                    <div className="container">
                        <StaticImage
                            src="../images/location.png"
                            layout="fullWidth"
                            placeholder="blurred"
                            className="image--location"
                        />
                        <div className="sectionTitle">
                            <h5 className="sans-serif uppercase light text--grey">Locatie</h5>
                            <h1 className="mainHeading">Pensiunea Koronka</h1>
                        </div>
                        <p className="paragraph">397H, Corunca, Mures</p>
                        <a href="https://goo.gl/maps/PLRTVTsr95DyhwfHA"  target="_blank" rel="noreferrer noopener" className="button button--large">Navigare</a>
                    </div>
                </section>
                <section className="section" ref={mentionsSection}>
                    <div className="container">
                        <div className="sectionTitle">
                            <h5 className="sans-serif uppercase light text--grey">Mentiuni</h5>
                            <h1 className="mainHeading">Ce mai e important</h1>
                        </div>
                        <h6 className="main_point">
                            Pentru a fi pregatiti si sa stiti la ce sa va asteptati, am insiruit in continuare o serie de detalii importante legate de desfasurarea evenimentelor.
                        </h6>
                        <div className="rule rule--animated">
                            <div className="ruleImage" id="thirdCircle">
                                <StaticImage
                                    src="../images/dresscode.png"
                                    layout="fullWidth"
                                    placeholder="blurred"
                                />
                            </div>
                            <div className="ruleTitle">
                                <h2 className="secondHeading point">
                                    <span className="diamond"></span>
                                    Dresscode
                                </h2>
                                {/* <button className="button button--small">Apasa</button> */}
                            </div>
                            <p className="paragraph">
                                Inexistent. Nu o sa fie dresscode. Vrem sa ne simtim
                                bine. E in weekendul de Halloween deci daca vreti sa
                                veniti cu cel mai smecher trelling Adidas sau cu
                                costumul ala special la care ati fost la ultima
                                nunta in care nu va puteti misca, nimeni nu va
                                judeca.
                            </p>
                            <p className="small text--gre">
                                Nota: Sunt sanse mari ca Dan sa fie imbracat in
                                trening-ul lui preferat toata ziua.
                            </p>
                        </div>
                        <div className="rule rule--animated">
                            <div className="ruleImage">
                                <StaticImage
                                    src="../images/accomodation.png"
                                    layout="fullWidth"
                                    placeholder="blurred"
                                />
                            </div>
                            <div className="ruleTitle">
                                <h2 className="secondHeading point">
                                    <span className="diamond"></span>
                                    Cazare
                                </h2>
                                {/* <button className="button button--small">Apasa</button> */}
                            </div>
                            <p className="paragraph">
                                Locatia unde o sa fie nunta are si locuri de cazare,
                                mai mult decat decente, in special pentru cei care
                                faceti un efort sa va deplasati pana acolo. Ne-am
                                gandit la tot, speram.
                            </p>
                            <p className="small text--gre">
                                Nota: Sunt sanse mari ca Dan sa fie imbracat in
                                trening-ul lui preferat chiar si noaptea.
                            </p>
                        </div>
                        <div className="rule">
                            <div className="ruleTitle">
                                <h2 className="secondHeading point">
                                    <span className="diamond"></span>
                                    Meniu
                                </h2>
                                {/* <button className="button button--small button--disabled">Nu apasa</button> */}
                            </div>
                            <p className="paragraph">
                                Evitam sa punem meniul aici pentru ca poate suferi
                                modificari, dar sunt cateva certitudini. O sa avem
                                variante vegetariene si o sa avem meniuri speciale
                                pentru intolerante si alergii. Va rugam sa ne
                                mentionati tipul de meniu dorit inainte.
                            </p>
                            <p className="small text--gre">
                                Nota: Sigur nu o sa fie peste. Pestii vin cu bagaj,
                                pot sa atraga situatii neplacute, lucruri ilegale,
                                alea, alea.
                            </p>
                        </div>
                        <div className="rule rule--animated">
                            <div className="ruleImage">
                                <StaticImage
                                    src="../images/music.png"
                                    layout="fullWidth"
                                    placeholder="blurred"
                                />
                            </div>
                            <div className="ruleTitle">
                                <h2 className="secondHeading point">
                                    <span className="diamond"></span>
                                    Muzica
                                </h2>
                                {/* <button className="button button--small">Apasa</button> */}
                            </div>
                            <p className="paragraph">
                                Subiectul asta este unul delicat si intelegem pe
                                deplin daca asta va face diferenta in prezenta
                                voastra, dar credem ca transparenta e importanta. Nu
                                o sa avem dansul pinguinului si muzica populara.
                                Ocazionala manea poate sa se strecoare pe ici pe colo,
                                dar nu o sa exageram.
                            </p>
                            <p className="small text--grey">
                                Nota: E posibil ca si Carmen sa poarte treningul
                                preferat a lui Dan.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <div className="sectionTitle">
                            <h5 className="sans-serif uppercase light text--grey">Cam atat,</h5>
                            <h1 className="mainHeading">Vorbim.</h1>
                        </div>
                        <h6 className="main_point">
                            O facem pentru a sarbatori impreuna cu voi ceea ce noi
                            traim deja de cativa ani buni. O facem simbolic, pentru
                            noi.
                        </h6>
                        <p className="paragraph">
                            Fiecare dintre voi sunteti invitati pentru ca sunteti importanti
                            pentru noi si ne-am simti mai mult decat fericiti
                            sa ne fiti alaturi. De-abia asteptam.
                        </p>
                        <div className="align--center">
                            <a href="https://forms.gle/JhekHcm9qUsBLWHu7"  target="_blank" rel="noreferrer noopener" className="button button--large" >Partikip</a>
                        </div>
                    </div>
                </section>
            </div>
            <section className="footer">
                <div className="container">
                    <p className="small text--grey">Copyright © {new Date().getFullYear()} Fam. Vidrasan. All rights reserved.</p>
                </div>
            </section>
        </main>
    );
};

export default IndexPage;
